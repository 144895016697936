.footerBox {
  position: fixed;
  left: auto;
  right: 0;
  top: 0;
  bottom: auto;
  width: 7rem;
  height: 100%;
  background: #FE2B4E;
  z-index: 5;
  /* background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.6),
      rgba(255, 255, 255, 0.6)
    ),
    linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.5) 0%,
      rgba(150, 150, 150, 0.5) 100%
    ); */
  background: #fff;
  /* backdrop-filter: blur(34px); */
}
.closeNotice {
  position: fixed;
  left: 0;
  top: 0;
  margin: 0.8rem 1rem;
  z-index: 98;
  max-width: 10rem;
  cursor: pointer;
  transition: 0.25s ease-in;
  transform: scale(0.9);
}
.closeNotice:hover {
  transform: scale(1);
}
.closedPopup {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 111111;
  padding: 2rem;
}
.closedPopup div {
  width: fit-content;
  background-color: #fff;
  padding: 1rem 2rem;
  position: relative;
  border-radius: 0.5rem;
  animation: drop 0.4s linear;
  transform: translateY(5rem);
}
@keyframes drop {
  0% {
    transform: translateY(-5rem);
  }
  100% {
    transform: translateY(5rem);
  }
}
.closedPopup div h2 {
  color: #b62738;
  font-weight: bold;
}
.closedPopup div img {
  position: absolute;
  top: -1rem;
  width: 2.5rem;
  right: -1rem;
  cursor: pointer;
}
.loggedin-page .stages-header__menu {
  display: block;
}
.loggedin-page .stages-header__menu li {
  margin-bottom: 1.5rem;
}

/* Icon Menu */
.bottom-icons-nav {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 95%;
  overflow: auto;
  list-style-type: none;
  /* background: #000; */
  z-index: 3;
  min-height: 5.375rem;
}
.bottom-icons-nav > li {
  width: 100%;
  font-size: 1.5rem;
  line-height: 1.5rem;
}
.bottom-icons-nav > li > a {
  color: rgba(255, 255, 255, 0.3);
  height: 100%;
  text-transform: uppercase;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  text-decoration: none;
  cursor: pointer;
}
.bottom-icons-nav > li > a.active {
  color: rgba(255, 255, 255, 1);
}
.desktop__logo {
  padding: 1.1875rem 1rem;
  opacity: 1;
  min-height: 9.375rem;
  /* display: none; */
}

.icon-lg {
  font-size: 1.7rem;
}

.bottom-icons-nav.bottom-icons-nav--withtext > li {
  font-size: 1.25rem;
}
.bottom-icons-nav.bottom-icons-nav--withtext > li > a {
  padding: 0.934rem 1rem;
  color: #fff;
}
.bottom-icons-nav.bottom-icons-nav--withtext > li > a > i {
  margin-bottom: 0.75rem;
  display: inline-block;
}
.bottom-icons-nav.bottom-icons-nav--withtext > li > a.active {
  background: linear-gradient(0deg, #0045B5, #0045B5),
    linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.5) 0%,
      rgba(150, 150, 150, 0.5) 100%
    );
  backdrop-filter: blur(34px);
}
.bottom-icons-nav.bottom-icons-nav--withtext > li > a.active > i::before {
  color: #0045B5;
}
.bottom-icons-nav.bottom-icons-nav--withtext > li > a > i::before {
  color: #fff !important;
}
.bottom-icons-nav.bottom-icons-nav--withtext > li > a.active > i::before {
  color: #fff !important;
}

.bottom-icons-nav.bottom-icons-nav--withtext > li > a > span {
  display: block;
  font-size: 0.75rem;
  line-height: 0.85rem;
  font-family: Calibri;
  font-weight: 800;
  color: #fff !important;
}
.bottom-icons-nav.bottom-icons-nav--withtext > li > a.active > span {
  color: #fff !important;
}

.has-notification > i {
  position: relative;
}
.has-notification > i:after {
  position: absolute;
  content: "";
  right: -7px;
  top: -7px;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background: #f34343;
}

.menuBackButton a span {
  font-size: 1.15rem !important;
}
/* Tablet */
@media only screen and (min-width: 991px) {
  .has-right-menu {
    position: relative;
    margin-right: 7rem;
    width: auto;
  }
  .loggedin-page .stages-header__menu {
    display: block;
    padding-right: 8rem;
  }

  .desktop__logo {
    display: block;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .footerBox {
    left: auto;
    bottom: auto;
    right: 0;
    top: 0;
    z-index: 3;
    width: 7rem;
    background: #FE2B4E;
    height: 100%;
  }

  .bottom-icons-nav {
    padding-top: 0rem;
    flex-direction: column;
    height: 80%;
    overflow: auto;
    z-index: 5;
  }
  .bottom-icons-nav > li {
    margin: 1rem 0;
    width: 100%;
    font-size: 2rem;
  }
  .bottom-icons-nav > li > a {
    padding: 2rem 1rem;
  }
  .bottom-icons-nav.bottom-icons-nav--withtext > li > a {
    padding: 1rem 1rem;
  }
}

/* mobile */
@media only screen and (max-width: 991px) {
  .footerBox {
    z-index: 60;
    background: #FE2B4E;
  }

  .mobile-video {
    width: calc(70vh * 1.77) !important;
    height: 70vh !important;
  }
}

.comments-btn {
  position: fixed;
  background-color: #000;
  top: 0;
  right: 7rem;
  margin: 1rem;
  border: 0;
  outline: 0;
  padding: 1rem 1rem;
  border-radius: 0.5rem;
  color: #fff;
  font-size: 1.23rem;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  transition: 0.25s ease-in;
}
.comments-btn:hover {
  transform: scale(1.07);
}
.ios-reactions {
  top: 10vh;
}
.ios-reactions .chatButton {
  backdrop-filter: blur(0px) !important;
  border-bottom: 0.5px solid rgb(255, 255, 255) !important;
  background: rgba(0, 114, 172, 0.3) !important;
}
@media (max-width: 991px) {
  .comments-btn {
    right: 0rem;
  }
  .chatButton {
    backdrop-filter: blur(0px) !important;
    border-bottom: 0.5px solid rgb(255, 255, 255) !important;
    background: rgba(0, 114, 172, 0.3) !important;
  }
  .chatButton div {
    padding: 0.5rem !important;
    justify-content: center;
  }
}
@media only screen and (max-width: 991px) and (orientation: landscape) {
  .tutCardContainer-body {
    max-height: 45vh !important;
    justify-content: flex-start !important;
  }
}
.bt-5-rem {
  margin: 2rem 2rem 7rem 2rem !important;
}
@media (min-width: 1024px) {
  .ios-video {
    height: 100% !important;
    width: calc(100vw - 7rem) !important;
    position: fixed !important;
    left: 0 !important;
    top: 50% !important;
    transform: translateY(-50%) !important;
  }
}
@media (max-width: 991px) and (orientation: portrait) {
  #audiVideoFrame {
    height: 100% !important;
    width: calc(100vw) !important;
    position: fixed !important;
    left: 0 !important;
    top: 50% !important;
    transform: translateY(-50%) !important;
  }
  #audiVideoFrame.w-s-m {
    top: 0 !important;
    transform: translateY(-30%) !important;
  }
}
