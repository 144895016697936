.languageDiv {
  position: fixed;
  z-index: 3;
  right: 0;
  top: 0;
  margin: 1rem 7.8rem 0rem 0rem;
  padding: 0.8rem 2rem;
  background-color: #000;
  color: #fff;
  display: flex;
  align-items: center;
}
.languageDiv p {
  font-weight: bold;
  font-size: 1rem;
}
#language-selection {
  outline: 0;
  border: 0;
  padding: 0.15rem 0.5rem;
  font-size: 0.9rem;
  text-transform: uppercase;
  cursor: pointer;
  background-color: #000;
  color: #fff;
}
@media (max-width: 991px) {
  .languageDiv {
    margin: 0.8rem 0.8rem 0rem 0rem;
  }
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #757575;
}

.ios-page {
  /* display: none; */
  width: calc(100% - 7rem);
}
.ios-page.has-right-menu {
  margin-right: 7 !important;
}
.lobby-ios {
  height: 100vh !important;
  width: calc(100% - 7rem) !important;
}
.ios-page #FrameImage,
.ios-page #FrameVideo,
.ios-page .TransitionVideo {
  height: 100vh !important;
  width: 233vh !important;
}
.ios-page #overlayContent {
  height: 100vh !important;
  width: 233vh !important;
}
.ios-page #leftArrow {
  display: block !important;
}
.ios-page #rightArrow {
  display: block !important;
  right: 8rem !important;
}
@media (max-width: 991px) {
  .footer-ios {
    position: absolute;
    top: 0;
    right: 0;
    width: 7rem;
    padding-bottom: 10rem;
    background-color: #FE2B4E;
    height: 100vh;
    bottom: auto;
    left: auto;
    max-height: 100vh;
    /* overflow-y: auto; */
  }
}
.footer-ios ul {
  height: 100%;
  flex-direction: column;
  padding-top: 1rem;
}
.footer-ios ul li {
  width: 100%;
  margin-bottom: 1rem;
}
.ios-page .submenu-container {
  position: fixed;
  left: unset;
  right: 7rem;
  top: 0;
  /* height: 100vh !important; */
  height: calc(100vh - 7rem) !important;

  width: 30rem;
}
.ios-page #audiVideoFrame {
  height: 100vh !important;
}
.ios-page .user-profile {
  top: 0;
  bottom: unset;
  right: 7rem;
  width: 30rem;
  height: calc(100vh - 7rem);
  z-index: 11111;
  position: fixed;
}

.ios-15 #FrameImage,
.ios-15 #FrameVideo,
.ios-15 .TransitionVideo {
  width: calc((100vh - 7rem) * 2.33) !important;
  height: calc(100vh - 7rem) !important;
}
.ios-15 #overlayContent {
  width: calc((100vh - 7rem) * 2.33) !important;
  height: calc(100vh - 7rem) !important;
}

.ios-15 .submenu-container {
  position: fixed;
  left: unset;
  right: 7rem;
  top: 0;
  height: calc(100vh - 9rem) !important;
  width: 30rem;
}
.ios-15 #audiVideoFrame {
  height: calc(100vh - 9rem) !important;
}
.ios-15 .user-profile {
  top: 0;
  bottom: unset;
  right: 7rem;
  width: 30rem;
  height: calc(100vh - 9rem);
  z-index: 11111;
  position: fixed;
}
.ios-15 .commentSection {
  height: 60vh !important;
  width: 75vw !important;
}
.ios-15 .box-cont {
  height: calc(60vh - 10rem) !important;
  overflow-y: auto;
}

.ios-15 #FrameImage,
.ios-15 #FrameVideo,
.ios-15 .TransitionVideo {
  width: calc((100vh - 7rem) * 2.33) !important;
  height: calc(100vh - 7rem) !important;
}
.ios-15 #overlayContent {
  width: calc((100vh - 7rem) * 2.33) !important;
  height: calc(100vh - 7rem) !important;
}

.ios-15-ch .submenu-container {
  position: fixed;
  left: unset;
  right: 7rem;
  top: 0;
  height: calc(100vh - 4rem) !important;
  width: 30rem;
}
.ios-15-ch #audiVideoFrame {
  height: calc(100vh - 4rem) !important;
}
.ios-15-ch .user-profile {
  top: 0;
  bottom: unset;
  right: 7rem;
  width: 30rem;
  height: calc(100vh - 4rem);
  z-index: 11111;
  position: fixed;
}
.ios-14-2 #FrameImage,
.ios-14-2 #FrameVideo,
.ios-14-2 .TransitionVideo {
  width: calc((100vh - 7rem) * 2.33) !important;
  height: calc(100vh - 7rem) !important;
}
.ios-14-2 #overlayContent {
  width: calc((100vh - 7rem) * 2.33) !important;
  height: calc(100vh - 7rem) !important;
}
.ios-14-2 #audiVideoFrame {
  height: calc(100vh - 7rem) !important;
}
.ios-14-2 .user-profile {
  top: 0;
  bottom: unset;
  right: 7rem;
  width: 30rem;
  height: calc(100vh - 7rem);
  z-index: 11111;
  position: fixed;
}
.ios-14-2 .submenu-container {
  position: fixed;
  left: unset;
  right: 7rem;
  top: 0;
  height: calc(100vh - 7rem) !important;
  width: 30rem;
}
@media (min-width: 1000px) {
  .ios-page {
    width: 100% !important;
  }
}

/* @media (min-width: 991px and max-width:1300px) {} */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .ipad-submenu-container {
    height: calc(100vh - 7rem) !important;
  }

  .ipad-audi-arrows {
    display: none !important;
  }
}

@media (max-width: 991px) {
  .ios-media-modal-content-iframe {
    max-height: calc(100vh - 7rem);
  }
}
